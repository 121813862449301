import { ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { usePracticeService } from '@/core/services';
import { Practice, StoredFolder } from '@/core/models'; //need to change to entity model

export const usePracticeStore = defineStore('practice', () => {
  // services
  const practiceService = usePracticeService();

  // state
  const currentPractice = ref<Practice>({} as Practice);

  // getters

  // actions
  const fetchCurrentPractice = async () => {
    const result = await practiceService.getCurrent();
    currentPractice.value = result;
    return result;
  };

  const fetchFolders = () => {
    return practiceService.getFolders();
  };

  const deleteFolder = (folderPath: string) => {
    return practiceService.deleteFolder(folderPath);
  };

  const createFolder = (path: string, updateEntities: boolean) => {
    return practiceService.createFolder(path, updateEntities);
  };

  const save = (data: Practice, options?: any) => {
    return practiceService.post([data], options);
  };

  const uploadLogo = (logo: File) => {
    return practiceService.uploadLogo(logo);
  };

  const renameFolder = (folder: StoredFolder, newName: string) => {
    return practiceService.renameFolder(folder, newName);
  };

  const updateAuditorFolder = (folderName: string) => {
    return practiceService.updateAuditorFolder(folderName);
  };

  const fetchDetail = (practiceId: string) => {
    return practiceService.getDetail(practiceId);
  };

  const state = {
    currentPractice
  };

  const actions = {
    fetchCurrentPractice,
    save,
    uploadLogo,
    fetchFolders,
    renameFolder,
    deleteFolder,
    createFolder,
    updateAuditorFolder,
    fetchDetail
  };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePracticeStore, import.meta.hot));
}
