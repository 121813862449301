<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue';
import * as _ from 'lodash-es';

// events
const onPreloadError = (event: Event) => {
  event.preventDefault();
  console.log('onPreloadError');
  if (window.parent !== window.top) {
    window.parent.location.reload();
  } else {
    window.location.reload();
  }
};

// hooks
onMounted(() => {
  window.addEventListener('vite:preloadError', onPreloadError);

  // TODO: Remove when this can be referenced globally
  Date.prototype.toJSON = function () {
    function ft(n: number) {
      // Format year ints to have at least four digits.
      return n < 1000 ? '000' + n : n;
    }

    function f(n: number) {
      // Format integers to have at least two digits.
      return n < 10 ? '0' + n : n;
    }

    return (
      ft(this.getFullYear()) +
      '-' +
      f(this.getMonth() + 1) +
      '-' +
      f(this.getDate()) +
      'T' +
      f(this.getHours()) +
      ':' +
      f(this.getMinutes()) +
      ':' +
      f(this.getSeconds()) +
      '.' +
      f(this.getMilliseconds())
    );
  };

  const parseISO8601Date = (iso8601: string) => {
    let s = _.trim(iso8601);
    s = _.replace(_.replace(/-/, '/'), /-/, '/');
    s = _.replace(_.replace(/T/, ' '), /Z/, '');
    s = _.replace(/([+-]\d\d):?(\d\d)/, ' $1$2'); // -04:00 -> -0400
    s = _.replace(/\.[0-9]{1,9}/, ''); //Removes milliseconds.
    return new Date(s);
  };

  // function parseISO8601DateTest(iso8601) {
  //     if (iso8601.indexOf('Z') === -1 && iso8601.indexOf('+') === -1)
  //         iso8601 += 'Z';
  //     console.log('IsoDate Test date: ' + iso8601);
  //     return new Date(iso8601);
  // }

  let _jsonParse: any;
  if (!_.isUndefined(JSON)) {
    _jsonParse = JSON.parse;

    //JSON2 is required to use this method.
    JSON.parse = (jsonText) => {
      return _jsonParse(jsonText, (key: string, value: string) => {
        let a: any;
        //var b;
        if (_.isString(value) && value.match(/^(\d{4}-\d\d-\d\d[tT]([\d:.]*)?)([zZ]|([+-])(\d{3}))?$/)) {
          //console.log('Raw date: ' + value);

          a = parseISO8601Date(value);
          //b = parseISO8601DateTest(value);

          // console.log('Parsed date: ' + a);
          // console.log('Parsed Test date: ' + b);

          if (a && !isNaN(a)) {
            return a;
          }
        }
        return value;
      });
    };
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('vite:preloadError', onPreloadError);
});
</script>

<template>
  <RouterView />
</template>

<style scoped lang="scss"></style>
