<script setup lang="ts">
import * as _ from 'lodash-es';
import { onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { Icon } from '@iconify/vue';
import { User } from 'oidc-client-ts';
import { useEntityStore, usePracticeStore, useRootStore, useUserStore } from '@/stores';
import { useAppStore, useAuthStore } from '@repo/vue/stores';
import { container as WidgetContainerModal } from 'jenesius-vue-modal';
import { DxLoader, DxLoaderBar, DxLoaderBlock, DxLoaderSpinner } from '@repo/vue/components';
import { useDxProgress } from '@repo/vue/utils';

// utils
const route = useRoute();
const { isLoadingSpinner } = useDxProgress();

// stores
const appStore = useAppStore();
const rootStore = useRootStore();
const entityStore = useEntityStore();
const userStore = useUserStore();
const router = useRouter();
const authStore = useAuthStore();
const practiceStore = usePracticeStore();

// state
const { isIFrame } = storeToRefs(appStore);
const { currentEntity } = storeToRefs(entityStore);
const { currentUser } = storeToRefs(userStore);
const { userProfileId } = storeToRefs(rootStore);
const { isLoading } = storeToRefs(authStore);
const { currentPractice } = storeToRefs(practiceStore);

// methods
const init = async () => {
  if (route.query.entityId) {
    rootStore.setEntityId(route.query.entityId.toString());
  }
  if (route.query.practiceId) {
    rootStore.setPracticeId(route.query.practiceId.toString());
  }

  currentPractice.value = await practiceStore.fetchCurrentPractice();
  if (currentPractice.value?.id) {
    rootStore.setPracticeId(currentPractice.value?.id);
  }

  currentEntity.value = await entityStore.fetchCurrentEntity();
  currentUser.value = await userStore.fetchCurrentUser();

  if (currentEntity.value?.id && _.isEqual(_.toLower(route.path), '/files')) {
    const pathRoute = { name: 'files', query: {} as any };

    if (route.query.openPath) pathRoute.query.openPath = route.query.openPath;

    if (route.query.openFile) pathRoute.query.openFile = route.query.openFile;

    await router.push(pathRoute);
  } else if (currentEntity.value?.id && _.isEqual(_.toLower(route.path), '/requests')) {
    const pathRoute = { name: 'requests', query: {} as any };
    if (route.query.openFile) pathRoute.query.openFile = route.query.openFile;
    if (route.query.requestId) pathRoute.query.requestId = route.query.requestId;

    await router.push(pathRoute);
  } else if (currentEntity.value?.id) {
    await router.push({ name: 'requests' });
  } else {
    await router.push({ name: 'entities' });
  }
};

const clearRootStoreData = () => {
  const search = window.location.search;
  if ((_.includes(search, 'code=') || _.includes(search, 'error=')) && _.includes(search, 'state=')) {
    rootStore.clearCurrentUser();
    rootStore.clearCurrentEntity();
    console.log('rootStore data reset');
  }
};

const setRootStoreData = (user: User) => {
  rootStore.$patch({
    userProfileId: user.profile?.sub
  });

  console.log('rootStore data saved');
};

authStore.$subscribe(
  (mutation, state) => {
    const user = state.user;
    if (user?.access_token) {
      if (user && user.access_token) {
        clearRootStoreData();
        setRootStoreData(user);
      }
    }
  },
  { immediate: true }
);

// watches
watch(
  userProfileId,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      console.log(currentUser.value);
    }
  },
  { immediate: true }
);

// hooks
onMounted(async () => {
  await init();
});
</script>

<template>
  <template v-if="isLoading">
    <DxLoader />
  </template>
  <template v-else>
    <RouterView />
    <DxLoaderBlock :blocked="isLoadingSpinner" />
    <DxLoaderBar v-if="!isIFrame" />
    <DxLoaderSpinner />
    <Toast />
    <ConfirmDialog>
      <template #message="slotProps">
        <Icon icon="line-md:confirm" :class="slotProps.message.icon" />
        <span class="p-confirm-dialog-message" v-html="slotProps.message.message"></span>
      </template>
    </ConfirmDialog>
    <DynamicDialog />
    <WidgetContainerModal />
  </template>
</template>
