import { api } from '@repo/core/config';
import { Engagement, Entity } from '@/core/models';

export const useEntityService = () => {
  const get = async (parameters?: string): Promise<Entity[]> => {
    parameters = parameters || '';

    try {
      const response = await api.get('entities?$filter=enabled eq true' + parameters);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getCurrent = async (): Promise<Entity> => {
    try {
      const response = await api.get(`entities/getCurrent?$expand=*`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getEngagements = async (key: string | undefined): Promise<Engagement[]> => {
    try {
      const response = await api.get(`entities/GetEngagements/${key}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const post = async (data: Entity[], options: any) => {
    if (!options) options = {};
    options.autoSave = options.autoSave != null ? options.autoSave : true;

    try {
      const response = await api.post(`entities`, data, { headers: options });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get,
    getCurrent,
    post,
    getEngagements
  };
};
