import { api } from '@repo/core/config';
import { User } from '@/core/models';

export const useUserService = () => {
  const current = async (): Promise<User> => {
    try {
      const response = await api.get('users/current');

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    current
  };
};
