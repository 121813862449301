<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue';
import * as _ from 'lodash-es';

// events
const onPreloadError = (event: Event) => {
  event.preventDefault();
  console.log('onPreloadError');
  if (window.parent !== window.top) {
    window.parent.location.reload();
  } else {
    window.location.reload();
  }
};

const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};

// hooks
onMounted(() => {
  window.addEventListener('vite:preloadError', onPreloadError);
});

onBeforeUnmount(() => {
  window.removeEventListener('vite:preloadError', onPreloadError);
});
</script>

<template>
  <div v-if="isMobile()">
    <h3 class="tw-flex tw-justify-center tw-align-middle tw-h-full tw-p-5 tw-mt-72">
      Unfortunately the client portal is not available for mobile...yet
    </h3>
  </div>
  <RouterView v-else />
</template>

<style scoped lang="scss"></style>
