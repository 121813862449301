import { ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useEntityService } from '@/core/services';
import { Entity } from '@/core/models'; //need to change to entity model

export const useEntityStore = defineStore('entity', () => {
  // services
  const entityService = useEntityService();

  // state
  const currentEntity = ref<Entity>({} as Entity);

  // getters

  // actions
  const fetchEntities = () => {
    return entityService.get();
  };

  const fetchEngagements = (key: string | undefined) => {
    return entityService.engagements(key);
  };

  const fetchCurrent = async () => {
    const result = await entityService.current();
    currentEntity.value = result;
    return result;
  };

  const saveEntities = async (data: Entity[], options?: any) => {
    const result = await entityService.post(data, options);
    await fetchEntities();
    return result;
  };

  const state = {
    currentEntity
  };

  const actions = {
    fetchEntities,
    fetchCurrent,
    saveEntities,
    fetchEngagements
  };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEntityStore, import.meta.hot));
}
